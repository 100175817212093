const JWT_KEY = 'JWT'

function parseJwt(token): {id: number} {
  const base64Payload = token.split('.')[1]
  const payload = Buffer.from(base64Payload, 'base64')
  return JSON.parse(payload.toString())
}

export type JwtResponse = {
  jwt: string,
}
export function isLoggedIn(): boolean {
  return !!getLocalStorage().getItem(JWT_KEY)
}

export function setToken(token: string): void {
  getLocalStorage().setItem(JWT_KEY, token)
}

export function getToken(): string {
  return getLocalStorage().getItem(JWT_KEY)
}

export function getUserId(): number {
  const token = getToken()
  if (token) return parseJwt(token).id
  return 0
}


export function deleteToken(): void {
  getLocalStorage().removeItem(JWT_KEY)
}


function getLocalStorage(): Storage {
  return window.localStorage
}
