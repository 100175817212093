import React, { ReactElement, useContext, useState } from 'react'
import clsx from 'clsx'
import { Link, navigate } from 'gatsby'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CalendarToday from '@material-ui/icons/CalendarToday'
import PersonIcon from '@material-ui/icons/Person'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LogoutIcon from '@material-ui/icons/ExitToApp'

import { IconButton, Tooltip } from '@material-ui/core'
import { toolbarClassSize, contentHeight } from '../styles/sizes'
import { RoundedContentComponent } from './rounded-content.component'
import { AppContentComponent } from './app/app-content.component'
import { deleteTheme } from '../utils/local_storage/theme.storage'
import { GlobalContext } from '../contexts/global.store'
import { getLogoUrl, getDefaultLogoUrl } from '../utils/logo'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    ...toolbarClassSize(theme),
  },
  toolbarSpacer: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    ...toolbarClassSize(theme),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 56,
    padding: theme.spacing(0, 1),
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  logo: {
    cursor: 'pointer',
    height: '34px',
  },
  logout: {
    color: '#FFF',
  },
  main: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    ...contentHeight(theme),
  },

}))

type LayoutProps = {
  children: React.ReactNode
}

export default function LayoutComponent({
  children,
}: LayoutProps): ReactElement {
  const classes = useStyles()
  const materialTheme = useTheme()
  const [globalState, globalDispatch] = useContext(GlobalContext)
  const { theme } = globalState
  const [open, setOpen] = React.useState(false)
  const [logoSrc, setLogoSrc] = useState(getLogoUrl(theme?.themeId))

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const logout = (): void => {
    globalDispatch({ type: 'LOGOUT' })
    deleteTheme()
    navigate('/login')
  }

  const toHome = (): void => {
    navigate('/')
  }

  return (

    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <button
            type="button"
            className="empty"
            onClick={(): void => {
              toHome()
            }}
          >
            <img
              src={logoSrc}
              onError={(): void => setLogoSrc(getDefaultLogoUrl())}
              alt="Logo"
              style={{ ...theme?.logo }}
              className={classes.logo}
            />
          </button>
          <Typography
            variant="h6"
            noWrap
            className={classes.title}
            onClick={(): void => {
              toHome()
            }}
          />
          <Link to="/account">
            <IconButton color="inherit">
              <PersonIcon />
            </IconButton>
          </Link>
          <IconButton onClick={(): void => logout()} className={classes.logout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
        <RoundedContentComponent />
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {materialTheme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/payments">
            <ListItem button key="Payments">
              <Tooltip title="Payments" placement="right" enterDelay={500}>
                <ListItemIcon>
                  <CalendarToday />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Payments" />
            </ListItem>
          </Link>
          <Link to="/uploads">
            <ListItem button key="Uploads">
              <Tooltip title="Uploads" placement="right" enterDelay={500}>
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Uploads" />
            </ListItem>
          </Link>
          <Link to="/users">
            <ListItem button key="Users">
              <Tooltip title="Users" placement="right" enterDelay={500}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>

        </List>
      </Drawer>
      <AppContentComponent>{children}</AppContentComponent>
    </div>
  )
}
